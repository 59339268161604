<template>
    <b-modal has-modal-card
             v-bind:active.sync="value">
        <div class="modal-card" style="width: 600px; ">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    <slot name="title">
                        Confirmation
                    </slot>
                </p>
            </header>
            <section class="modal-card-body">
                <div class="content">
                    <slot></slot>
                </div>

                <b-field style="justify-content: center; "
                         v-if="hasConfirm"
                         v-bind:type="!!errorMessage ? " is-danger
                ' : ''"
                v-bind:message="errorMessage">
                <b-input type="text"
                         v-bind:placeholder="textualConfirmPlaceholder"
                         required
                         v-model="textualConfirm"
                         v-on:focus.native="errorMessage = ''">
                </b-input>
                </b-field>
            </section>
            <footer class="modal-card-foot">
                <button class="button"
                        type="button"
                        v-on:click="cancel">
                    Cancel
                </button>
                <button class="button is-primary"
                        v-on:click="compare">
                    Confirm
                </button>
            </footer>
        </div>
    </b-modal>
</template>

<script>
    export default {
        name: 'ModalConfirmation',
        props: {
            value: {
                type: Boolean
            },

            hasConfirm: {
                type: Boolean,
                default: false
            },

            textualConfirmPlaceholder: {
                type: String
            },

            textualConfirmAnswer: {
                type: String
            },

            isFunction: {
                type: Boolean,
                default: false
            },

            confirmFunction: {
                type: Function
            },

            callback: {
                type: Function
            }
        },
        data() {
            return {
                errorMessage: '',
                textualConfirm: ''
            }
        },
        methods: {
            cancel() {
                this.value = false
                this.$emit('input', false)
            },

            async compare() {
                if (this.hasConfirm) {
                    if (!this.isFunction) {
                        if (this.textualConfirm !== this.textualConfirmAnswer) {
                            this.errorMessage = 'Incorrect answer. '
                            return
                        }
                    } else {
                        if (
                            this.confirmFunction &&
                            typeof this.confirmFunction === 'function'
                        ) {
                            let result = await this.confirmFunction(this.textualConfirm)
                            if (!result) {
                                this.errorMessage = 'Incorrect credential. '
                                return
                            }
                        }
                    }
                }

                if (
                    this.callback &&
                    typeof this.callback === 'function'
                ) {
                    this.callback()
                }

                this.errorMessage = ''
                this.textualConfirm = ''
                this.value = false
                this.$emit('input', false)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-card-foot {
        justify-content: flex-end;
    }
</style>
